import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Header } from '../containers/HeaderContainer/index'
import FooterContainer from '../containers/FooterContainer'
import useApp, { withAppContext } from '../contexts/context';
import SEO from '../components/SEO'
import { useSiteMetadata } from '../hooks/useQuerydata';
import Layout from '../components/Layout';
import { Sidebar } from '../components/Sidebar';
import { graphql } from 'gatsby';
import LandingPageContainer from '../containers/LandingPage/MainContent';

const LandingPage = ({ pageContext, data, ...props }) => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;

  const theme = {
    bgColor: 'white',
  }
  const dataPage = data?.prismicLandingPage?.data;
  if (!data) return null
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer
            data={allPrismicFooter?.nodes[0]?.data}
          />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={dataPage?.body1} />
        }
      >
        <LandingPageContainer data={dataPage?.body} />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(LandingPage);

export const query = graphql`
  query LandingPageQuery($id: String) {
    prismicLandingPage(id: {eq: $id}) {
      data {
        body {
          ... on PrismicLandingPageBodyBanner {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              banner {
                url
                alt
              }
              description {
                html
                text
                raw
              }
            }
            items {
              button_label {
                html
                text
                raw
              }
              button_link {
                url
                type
                link_type
              }
            }
          }
          ... on PrismicLandingPageBodyHighlight {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              link_label {
                html
                text
                raw
              }
              link {
                url
                type
                link_type
              }
            }
          }
          ... on PrismicLandingPageBodyExploreLocation {
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
            }
            slice_type
            items {
              location {
                document {
                  ... on PrismicLocationSpace {
                    data {
                      city {
                        html
                        text
                        raw
                      }
                      country {
                        html
                        text
                        raw
                      }
                      geo_location {
                        latitude
                        longitude
                      }
                      thumbnail {
                        url
                        alt
                      }
                      tags {
                        tag_name
                      }
                      body5 {
                        ... on PrismicLocationSpaceBody5AddressAndContacts {
                          primary {
                            email {
                              html
                              text
                              raw
                            }
                            phone_number {
                              html
                              text
                              raw
                            }
                          }
                        }
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyWhy {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              image {
                url
                alt
              }
            }
            items {
              description {
                html
                text
                raw
              }
              icon {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageBodyDen {
            slice_type
            primary {
              image {
                alt
                url
              }
              description {
                html
                text
                raw
              }
              den_logo {
                alt
                url
              }
              button_link {
                url
                type
                link_type
              }
              button_label {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyMediaBox {
            slice_type
            items {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              content {
                html
                text
                raw
              }
              shadow_color
              image_position
              background_color_content
              image {
                alt
                url
              }
              button_link {
                url
                type
                link_type
              }
              button_label {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyDshStory {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              blog {
                document {
                  ... on PrismicBlogDetail {
                    data {
                      title {
                        html
                        text
                        raw
                      }
                      release_date
                      author {
                        html
                        text
                        raw
                      }
                      image {
                        url
                        alt
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyBlockButton {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              button_label {
                html
                text
                raw
              }
              button_link {
                url
                type
                link_type
              }
            }
          }
          ... on PrismicLandingPageBodyFaqs {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              question {
                html
                text
                raw
              }
              answer {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyBenefit {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              description {
                html
                text
                raw
              }
              content {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyQuote {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              quote {
                html
                text
                raw
              }
              author {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyReviews {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              name {
                html
                text
                raw
              }
              position {
                html
                text
                raw
              }
              comment {
                html
                text
                raw
              }
              avatar {
                alt
                url
              }
            }
          }
          ... on PrismicLandingPageBodyMeetOurStartups {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              logo {
                url
                alt
              }
              raised_prefix {
                html
                text
                raw
              }
              raised {
                html
                text
                raw
              }
              min_investment {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyHowItWorks {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              icon {
                url
                alt
              }
              description {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyFundamentalsOfEntrepreneurship {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              content {
                html
                text
                raw
              }
              link {
                url
                type
                link_type
              }
              image {
                url
                alt
              }
              link_label {
                html
                text
                raw
              }
              start_date
            }
          }
          ... on PrismicLandingPageBodyJoinUs {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              image {
                alt
                url
              }
            }
            items {
              description {
                html
                text
                raw
              }
              content {
                html
                text
                raw
              }
            }
          }
          ... on PrismicLandingPageBodyTeam {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              member {
                document {
                  ... on PrismicTeamMembers {
                    data {
                      avatar {
                        url
                      }
                      contacts {
                        contact_link {
                          url
                          link_type
                          type
                        }
                        contact_label
                      }
                      name {
                        text
                      }
                      last_name {
                        text
                      }
                      position {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyPartner {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              description1 {
                html
                text
                raw
              }
            }
            items {
              partner {
                document {
                  ... on PrismicPartners {
                    data {
                      logo {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyText {
            primary {
              text {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBodyImage {
            items {
              image1 {
                url
                alt
              }
              caption {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBodyImageWithContent {
            primary {
              image_position
              image1 {
                alt
                url
              }
              content {
                html
                text
                raw
              }
            }
          }
        }
        body1 {
          ... on PrismicLandingPageBody1GeneralCard {
            primary {
              title {
                html
                text
                raw
              }
              robot {
                html
                text
                raw
              }
              image {
                url
                alt
              }
              googlebot {
                html
                text
                raw
              }
              favicon {
                alt
                url
              }
              description {
                html
                text
                raw
              }
              bingbot {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBody1OpenGraph {
            primary {
              og_url {
                url
                type
                link_type
              }
              og_type {
                html
                text
                raw
              }
              og_title {
                html
                text
                raw
              }
              og_site_name {
                html
                text
                raw
              }
              og_locale {
                html
                text
                raw
              }
              og_image_width {
                html
                text
                raw
              }
              og_image_height {
                html
                text
                raw
              }
              og_image {
                alt
                url
              }
              og_description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBody1TwitterCard {
            primary {
              title {
                html
                text
                raw
              }
              image {
                alt
                url
              }
              description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBody1PinterestProductPin {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              price
              currency
              availability
            }
          }
        }
      }
    }
  }
`