import React from 'react'
import styled from 'styled-components'
import { Question } from '../ContactContainer/Question'
import { BannerBottom } from '../DenContainer/BannerBottom'
import { Banner } from '../HomeContainer/MainContent/Banner'
import { DraperEnterpreneurialNetwork } from '../HomeContainer/MainContent/DraperEnterpreneurialNetwork'
import { HightLight } from '../HomeContainer/MainContent/HightLight'
import { LocationMapSection } from '../HomeContainer/MainContent/LocationMapSection'
import { Locations } from '../HomeContainer/MainContent/LocationsSection'
import { Stories } from '../HomeContainer/MainContent/Stories'
import { Ventures } from '../HomeContainer/MainContent/Ventures'
import { Why } from '../HomeContainer/MainContent/WhySection'
import { Benefit } from '../PartnerContainer/Benefit'
import { HightLight as HL } from '../AcceleratorContainer/MainContent/HightLight'
import { Testimoials } from '../SpaceConntainer/Testimoials'
import { SuccessStories } from '../VentureContainer/SucessStories'
import WhatYouCan from '../AcademyContainer/WhatYouCan'
import BecomeStartup from '../AcademyContainer/BecomeStartup'
import { JoinUs } from '../VentureContainer/JoinUs'
import { Team } from '../SpaceConntainer/Team'
import { Partners } from '../SpaceConntainer/Partners'
import { RichText } from 'prismic-reactjs'
import { Text } from '../../components/Text/Text';
import htmlSerializer from '../BlogDetailContainer/RichtextHelper/CustomText'

const RenderPage = ({ type, data }) => {
  switch (type) {
    case "banner":
      return <Banner
        title={data?.primary?.title}
        description={data?.primary?.description}
        cta={data?.items}
        banner={data?.banner}
      />
    case "highlight":
      return <HightLight
        data={data?.primary}
      />
    case "explore_location":
      const listLocation = data?.items.sort((a, b) => a?.location?.document?.data?.city?.text.localeCompare(b?.location?.document?.data?.city?.text))
      return (
        <>
          <LocationMapSection
            data={data?.primary}
            geolocations={listLocation}
          />
          {listLocation && (
            <Locations listItem={listLocation} />
          )}
        </>
      )
    case "why":
      return (
        <Why
          items={data?.items}
          primary={data?.primary}
        />
      )
    case "den":
      return (
        <DraperEnterpreneurialNetwork
          data={data?.primary}
        />
      )
    case "media_box":
      return (
        data?.items.map((item, i) => (
          <Ventures
            key={i}
            primary={item}
          />
        ))
      )
    case "dsh_story":
      return <Stories data={data} />
    case "block_button":
      return (
        <BannerBottom
          title={data?.primary?.title}
          button_label={data?.primary?.button_label}
          button_link={data?.primary?.button_link}
        />
      )
    case "faqs":
      return <Question data={data} />
    case "benefit":
      return <Benefit benefits={data?.items} title={data?.primary?.title?.raw} />
    case "quote":
      return <HL
        data={data?.primary}
      />
    case "reviews":
      return <Testimoials data={data} />
    case "meet_our_startups":
      return (
        <SuccessStories
          data={data}
        />
      )
    case "how_it_works":
      return <WhatYouCan data={data} />
    case "fundamentals_of_entrepreneurship":
      return (
        <BecomeStartup
          dataFundamental={data}
        />
      )
    case "join_us":
      return <JoinUs data={data} />
    case "team":
      return <Team data={data} />
    case "partner":
      return <Partners data={data} />
    case "text":
      return (
        <DivContainer>
          <RichText
          render={data?.primary?.text?.raw}
          htmlSerializer={htmlSerializer}
        />
        </DivContainer>
      );
    case "image":
      return (
        <DivContainer>
          <ImageBlog>
          {data?.items?.map((item, i) => (
            <DivImage>
              <Image key={i} src={item?.image1?.url}></Image>
              <ImageCaption>
                <RichText
                  render={item?.caption?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </ImageCaption>
            </DivImage>
          ))}
        </ImageBlog>
        </DivContainer>
      );
    case "image_with_content":
      return (
        <DivContainer>
          <ImageWithCaption>
          <ImageExpand
            src={data?.primary?.image1?.url}
            order={data?.primary?.image_position}
          ></ImageExpand>
          <Caption>
            <RichText
              render={data?.primary?.content?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Caption>
        </ImageWithCaption>
        </DivContainer>
      );
    default:
      break;
  }
}
const LandingPageContainer = ({ data }) => {
  return (
    <WrapperSection>
      {data?.map((item, index) => (
        <RenderPage key={index} data={item} type={item?.slice_type} />
      ))}
    </WrapperSection>
  )
}

export default LandingPageContainer;

const WrapperSection = styled.div`
  /* height: 100%; */
  
`;
const DivContainer = styled.div`
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const DivImage = styled.div``;
const ImageCaption = styled(Text)``;
const ImageBlog = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Image = styled.img`
  @media (max-width: 650px) {
    width: 100%;
    max-height: 250px;
  }
  /* max-width: fit-content; */
`;
const ImageWithCaption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Caption = styled(Text)``;
const ImageExpand = styled.img`
  max-width: 250px;
  order: ${(props) => (props.order === "left" ? 0 : 1)};
  @media (max-width: 650px) {
    width: 100%;
    max-height: 250px;
  }
  /* max-width: fit-content; */
`;